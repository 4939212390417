<template>
  <div>
    <x-table
      title="通知订阅"
      :card="true"
      :options="options"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowEnable="rowEnable"
      @rowDisable="rowDisable"
      @rowDelete="rowDelete">
    </x-table>
  </div>
</template>
<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  query, patch, remove, queryProducts, queryDevices, queryAlarms, disabled, enabled,
} from '@/api/system/usercenter/notification'

export default {
  components: {
    XTable,
  },
  data() {
    return {
      providers: [],
      fileList: [],
      produts: [],
      options: {
        formType: 'sidebar',
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        actions: [
          { action: 'view', name: '查看', icon: 'EyeIcon' },
          // actions: [{ action: 'view', name: '查看', icon: 'EyeIcon', url: '/device-center/device/view/{id}' },
          { action: 'edit', name: '编辑', icon: 'EditIcon' },
          { action: 'delete', name: '删除', icon: 'Trash2Icon' },
          { action: 'disable', name: '关闭', icon: 'SlashIcon' },
          { action: 'enable', name: '开启', icon: 'RepeatIcon' },
        ],
        actionFilter: (action, row) => {
          if (row.state.value === 'enabled') {
            return action === 'view' || action === 'edit' || action === 'disable'
          }
          return action === 'view' || action === 'edit' || action === 'delete' || action === 'enable'
        },
        columns: [{
          label: '订阅类型',
          labelShow: true,
          prop: 'topicProvider',
          rowSelect: true,
          type: 'select',
          dictUrl: 'notifications/providers',
          props: { label: 'name', value: 'id' },
          searchShow: false,
          onDictSelected: (option, column) => {
            if (option === this.$x.biz.ProviderType.DEVICE_ALARM) {
              column[2].addShow = true
              column[2].editShow = true
              queryProducts().then(resp => {
                let products = resp.data.result.data
                products.push({ id: '*', name: '全部' })
                column[2].children[0].dictData = resp.data.result.data
              })
            } else {
              column[2].addShow = false
              column[2].editShow = false
            }
          },
          rules: {
            rule: 'required',
            message: '请输入选择订阅类型',
          },
        }, {
          label: '订阅名称',
          labelShow: true,
          prop: 'subscribeName',
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入订阅名称',
          },
        }, {
          label: '配置',
          labelShow: true,
          rowShow: false,
          addShow: false,
          editShow: false,
          prop: 'topicConfig',
          searchShow: false,
          children: [
            {
              label: '产品',
              labelShow: true,
              rowShow: false,
              prop: 'productId',
              type: 'select',
              dictData: [],
              props: { label: 'name', value: 'id' },
              getDictLabel: option => option.name + `（${option.id})`,
              searchShow: false,
              onDictSelected: (option, column) => {
                if (option === '*') {
                  column[1].dictData = [{ id: '*', name: '全部' }]
                  column[2].dictData = [{ id: '*', name: '全部' }]
                  return
                }
                queryDevices(option).then(resp => {
                  let devices = resp.data.result.data
                  devices.push({ id: '*', name: '全部' })
                  column[1].dictData = devices
                })
                queryAlarms(option).then(resp => {
                  let alarms = resp.data.result.data
                  alarms.push({ id: '*', name: '全部' })
                  column[2].dictData = alarms
                })
              },
              rules: {
                rule: 'required',
                message: '请输入产品',
              },
            }, {
              label: '设备',
              labelShow: true,
              rowShow: false,
              prop: 'deviceId',
              type: 'select',
              dictData: [],
              props: { label: 'name', value: 'id' },
              getDictLabel: option => option.name + `（${option.id})`,
              searchShow: false,
              rules: {
                rule: 'required',
                message: '请输入选择设备',
              },
              onDictSelected: (option, column) => {
                queryAlarms(option).then(resp => {
                  let alarms = resp.data.result.data
                  alarms.push({ id: '*', name: '全部' })
                  column[2].dictData = alarms
                })
              },
            }, {
              label: '告警',
              labelShow: true,
              rowShow: false,
              prop: 'alarmId',
              type: 'select',
              dictData: [],
              props: { label: 'name', value: 'id' },
              getDictLabel: option => option.name + `（${option.id})`,
              searchShow: false,
              rules: {
                rule: 'required',
                message: '请输入选择告警',
              },
            },
          ],
        },
        {
          label: '状态',
          labelShow: true,
          prop: 'state',
          type: 'select',
          rowSelectDot: true,
          dictData: [{ value: 'enabled', text: '订阅中' }, { value: 'disabled', text: '已停止' }],
          selectVariant: prop => {
            if (prop.value === 'enabled') {
              return 'text-success'
            }
            return 'text-danger'
          },
          props: { label: 'text', value: 'value' },
          editShow: false,
          addShow: false,
          viewShow: false,
          searchShow: false,
        },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowEdit(data, done) {
      patch(data).then(() => {
        done()
        if (data.state.value === 'enabled') {
          this.subscribeAlarm(data)
        } else {
          this.$x.ws.unsubscribe('alarm', this.$x.ws.TYPE.ALARM)
        }
        console.log(data)
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
        if (data.state.value === 'enabled') {
          this.$x.ws.unsubscribe('alarm', this.$x.ws.TYPE.ALARM)
        }
      })
    },
    // 开启
    rowEnable(data, done) {
      enabled(data.id).then(() => {
        done()
        this.subscribeAlarm(data)
      })
    },
    // 关闭
    rowDisable(data, done) {
      disabled(data.id).then(() => {
        done()
        this.$x.ws.unsubscribe('alarm', this.$x.ws.TYPE.ALARM)
      })
    },
    addHandle(formData, done) {
      patch(formData).then(() => {
        done()
        this.subscribeAlarm(formData)
      })
    },
    subscribeAlarm(data) {
      let params = {
        productId: data.topicConfig.productId,
        deviceId: data.topicConfig.deviceId,
        alarmId: data.topicConfig.alarmId,
      }
      this.$x.ws.subscribe('alarm', this.$x.ws.TYPE.ALARM, params, msg => {
        console.log(msg)
        let alarmValue = ''
        Object.keys(msg.payload).forEach(item => {
          switch (item) {
            case 'alarmId':
            case 'alarmName':
            case 'deviceId':
            case 'deviceName':
            case 'productId':
            case 'productName':
            case 'timestamp':
            case 'trigger0':
              break
            default:
              alarmValue = msg.payload[item]
          }
        })
        this.$xtoast.error('产品（' + msg.payload.productName + '） 设备（' + msg.payload.deviceName + '）<br>' + alarmValue, msg.payload.alarmName)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
</style>
